import 'core-js';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactOnRails from 'react-on-rails';
import { hijackEffects } from 'stop-runaway-react-effects';

import OtherContributions from '../components/contributions/OtherContributions';

if (process.env.NODE_ENV !== 'production') {
  hijackEffects();
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

ReactOnRails.register({ OtherContributions });
