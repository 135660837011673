import { useRef } from 'react';

import AxiosInstanceFactory from '../utils/axiosInstance';

export default function useAxios(
  options: Record<string, unknown> = {}
): ReturnType<typeof AxiosInstanceFactory> {
  const axiosRef = useRef(AxiosInstanceFactory(options));

  return axiosRef.current;
}
