import axios from 'axios';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import ReactOnRails from 'react-on-rails';

import { has } from '../utils/typescript';

import type { AxiosInstance, CancelTokenStatic } from 'axios';

const DEFAULT_RETRY_COUNT = 3;

export interface Options extends Record<string, unknown> {
  retries?: number;
  timeout?: number;
  headers?: Record<string, string>;
}

export interface CancellableAxiosInstance extends AxiosInstance {
  CancelToken?: CancelTokenStatic;
}

function isRetriableError(_error: Error) {
  // For now, we want to retry all requests
  return true;
}

function retryCount(options: Options = {}): number {
  if (!has('retries', options)) return DEFAULT_RETRY_COUNT;

  return options.retries;
}

export default function axiosInstance(
  options: Options
): CancellableAxiosInstance {
  if (typeof window === 'undefined') return {} as CancellableAxiosInstance; // for server side rendering

  const defaultTimeout = 15000;
  const defaultHeaders = {
    ...ReactOnRails.authenticityHeaders({}),
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  const instance: CancellableAxiosInstance = axios.create({
    timeout: options.timeout || defaultTimeout,
    withCredentials: true,
    headers: { ...defaultHeaders, ...options.headers }
  }) as CancellableAxiosInstance;

  axiosRetry(instance, {
    retries: retryCount(options),
    retryCondition: isRetriableError,
    retryDelay: exponentialDelay
  });

  instance.CancelToken = axios.CancelToken;

  return instance;
}
