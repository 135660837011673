import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useCallback
} from 'react';
import styled from '@emotion/styled';

import useLockBodyScroll from '../../hooks/useLockBodyScroll';
import useWindowSize from '../../hooks/useWindowSize';

import { shareableImages } from '../../routes/projects';

import useAxios from '../../hooks/useAxios';

import closeIcon from '@images/home/spread/close_modal.svg';
import nextButton from '@images/modals/round_next.svg';
import prevButton from '@images/modals/round_previous.svg';

type PropsType = {
  projectSlug: string;
  projectTitle: string;
};

type TImagesProps = {
  activeImage: string;
  height: number;
  modalHeight: number;
  nextImage: string | null | undefined;
  prevImage: string | null | undefined;
  subtitleHeight: number;
  titleHeight: number;
};

type TShareableImage = [string, string];
type TShareableImages = Array<TShareableImage>;

type TModalProps = {
  images: TShareableImages;
  imagesLoaded: boolean;
  projectTitle: string;
  onClose: () => void;
};

const modalDesktopSideMargin = 40; // px
const modalMobileSideMargin = 10; // px
const desktopBreakpoint = 768; // px
const modalTopPosition = 57; // px
const pageSection = document.querySelector('body');

const Overlay = styled.div`
  cursor: default;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1100;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ModalBackground = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position: fixed;
`;

const Title = styled.h1`
  font-size: 1.6rem;
  margin: 0;
  padding: 2rem 4rem;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 2rem;
    padding: 3rem 10rem;
  }

  @media (min-width: 992px) {
    font-size: 3rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1rem;
  margin: 0;
  padding: 1rem;
  text-align: center;

  @media (min-width: 576px) {
    font-size: 1.4rem;
  }

  @media (min-width: 768px) {
    font-size: 1.8rem;
    padding: 2.5rem;
  }
`;

const ImageStrip = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
`;
const Image = styled.img`
  border: 1px solid #000000;
`;
const NavButton = styled.button((props) => {
  return {
    background: 'transparent',
    border: 'none',
    fontSize: 0,
    left: props.action === 'prev' ? '0.5rem' : 'auto',
    right: props.action === 'next' ? '0.5rem' : 'auto',
    padding: 0,
    position: 'absolute',

    '& img': {
      maxWidth: '100%'
    },

    '@media (min-width: 576px)': {
      left: props.action === 'prev' ? '1rem' : 'auto',
      right: props.action === 'next' ? '1rem' : 'auto'
    },

    '@media (min-width: 768px)': {
      left: props.action === 'prev' ? '2rem' : 'auto',
      right: props.action === 'next' ? '2rem' : 'auto'
    },

    '@media (min-width: 992px)': {
      left: props.action === 'prev' ? '3.6rem' : 'auto',
      right: props.action === 'next' ? '3.6rem' : 'auto'
    }
  };
});

const CloseButton = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  font-size: 0;
  padding: 0.3rem;
  right: 0.3rem;
  top: 0.3rem;

  @media (min-width: 768px) {
    right: 1rem;
    top: 1rem;
  }
`;

const CloseIcon = styled.img`
  @media (max-width: 767px) {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

function Images({
  activeImage,
  height,
  modalHeight,
  nextImage,
  prevImage,
  subtitleHeight,
  titleHeight
}: TImagesProps) {
  const [
    activeImageDistanceFromLeft,
    setActiveImageDistanceFromLeft
  ] = useState(0);
  const [
    activeImageDistanceFromRight,
    setActiveImageDistanceFromRight
  ] = useState(0);
  const [activeImageDistanceFromTop, setActiveImageDistanceFromTop] = useState(
    0
  );
  const [renderHeight, setRenderHeight] = useState(0);
  const [renderWidth, setRenderWidth] = useState(0);

  const activeImageRef = useRef(null);

  const windowSize = useWindowSize();

  const setActiveImageLocation = useCallback(() => {
    if (!activeImageRef.current) return;

    const imageStripWidth = activeImageRef.current.offsetParent.clientWidth;
    const { clientWidth, offsetLeft, offsetTop } = activeImageRef.current;

    setActiveImageDistanceFromLeft(offsetLeft);
    setActiveImageDistanceFromRight(imageStripWidth - clientWidth - offsetLeft);
    setActiveImageDistanceFromTop(offsetTop);
  }, []);

  const handleImageResize = useCallback(() => {
    if (!activeImageRef.current) return;

    setRenderHeight(activeImageRef.current.clientHeight);
    setRenderWidth(activeImageRef.current.clientWidth);
    setActiveImageLocation();
  }, [setActiveImageLocation]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleImageResize);

    const imageEl = activeImageRef.current;

    if (imageEl) {
      resizeObserver.observe(imageEl);
    }

    return () => {
      if (imageEl) {
        resizeObserver.unobserve(imageEl);
      }
    };
  }, [handleImageResize]);

  useEffect(() => {
    setActiveImageLocation();
  }, [modalHeight, setActiveImageLocation, windowSize.width]);

  useEffect(() => {
    handleImageResize();
  }, [handleImageResize]);

  const defaultNextImageOffset = 100;

  // Make sure images do not overlap center image. Make sure they are at least
  // 20px apart.
  const nextRightPosition = Math.min(
    defaultNextImageOffset,
    activeImageDistanceFromRight - 20
  );
  const prevLeftPosition = Math.min(
    defaultNextImageOffset,
    activeImageDistanceFromLeft - 20
  );

  return (
    <ImageStrip style={{ height }}>
      {prevImage ? (
        <Image
          location="prev"
          src={prevImage}
          style={{
            height: renderHeight,
            right: `calc(100% - ${prevLeftPosition}px)`,
            opacity: 0.3,
            position: 'absolute',
            top: activeImageDistanceFromTop,
            width: renderWidth
          }}
        />
      ) : null}
      {
        <Image
          location="active"
          ref={activeImageRef}
          src={activeImage}
          style={{ maxHeight: height, maxWidth: '80%' }}
        />
      }
      {nextImage ? (
        <Image
          location="next"
          src={nextImage}
          style={{
            height: renderHeight,
            left: `calc(100% - ${nextRightPosition}px)`,
            opacity: 0.3,
            position: 'absolute',
            top: activeImageDistanceFromTop,
            width: renderWidth
          }}
        />
      ) : null}
    </ImageStrip>
  );
}

function CloseControl({ onClick }) {
  return (
    <CloseButton onClick={onClick}>
      <CloseIcon src={closeIcon} />
    </CloseButton>
  );
}

function PrevButton({ height, onClick, top, width }) {
  return (
    <NavButton action="prev" style={{ height, top, width }} onClick={onClick}>
      <img src={prevButton} alt="" />
    </NavButton>
  );
}

function NextButton({ height, onClick, top, width }) {
  return (
    <NavButton action="next" style={{ height, top, width }} onClick={onClick}>
      <img src={nextButton} alt="" />
    </NavButton>
  );
}

function Controls({
  activeIndex,
  buttonTopOffset,
  imageCount,
  imageStripHeight,
  modalWidth,
  onClose,
  onNext,
  onPrev
}) {
  const [height, setHeight] = useState(0);
  const [top, setTop] = useState(0);
  const [width, setWidth] = useState(0);
  const naturalHeight = 47; // px
  const naturalWidth = 46; // px
  const heightWidthRatio = naturalHeight / naturalWidth;

  useLayoutEffect(() => {
    const maxButtonHeight = Math.min(
      (modalWidth / 15) * heightWidthRatio,
      naturalHeight
    );

    const buttonHeight = Math.min(imageStripHeight / 2, maxButtonHeight);
    const buttonWidth = buttonHeight / heightWidthRatio;

    setHeight(buttonHeight);
    setTop(buttonTopOffset + (imageStripHeight / 2 - buttonHeight / 2));
    setWidth(buttonWidth);
  }, [buttonTopOffset, heightWidthRatio, imageStripHeight, modalWidth]);

  return [
    <CloseControl key="close" onClick={onClose} />,
    activeIndex > 0 ? (
      <PrevButton
        height={height}
        top={top}
        width={width}
        key="prev"
        onClick={onPrev}
      />
    ) : null,
    activeIndex < imageCount - 1 ? (
      <NextButton
        height={height}
        top={top}
        width={width}
        key="next"
        onClick={onNext}
      />
    ) : null
  ];
}

function Modal({ images, imagesLoaded, projectTitle, onClose }: TModalProps) {
  const windowSize = useWindowSize();

  const [activeIndex, setActiveIndex] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [pageSectionWidth, setPageSectionWidth] = useState(0);
  const [subtitleHeight, setSubtitleHeight] = useState(0);
  const [subtitleText, setSubtitleText] = useState('');
  const [titleHeight, setTitleHeight] = useState(0);

  const modalRef = useRef(null);
  const subtitleRef = useRef(null);
  const titleRef = useRef(null);

  const sideMargin =
    windowSize.width >= desktopBreakpoint
      ? modalDesktopSideMargin
      : modalMobileSideMargin;

  useLockBodyScroll();

  const handleModalResize = useCallback(() => {
    if (!titleRef.current) return;
    setTitleHeight(titleRef.current.clientHeight);

    if (!subtitleRef.current) return;
    setSubtitleHeight(subtitleRef.current.clientHeight);
  }, []);

  const handleClickNext = useCallback(() => {
    setActiveIndex(Math.min(activeIndex + 1, images.length - 1));
  }, [activeIndex, images.length]);

  const handleClickPrev = useCallback(() => {
    setActiveIndex(Math.max(activeIndex - 1, 0));
  }, [activeIndex]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleModalResize);

    const modalEl = modalRef.current;
    const titleEl = titleRef.current;
    const subtitleEl = subtitleRef.current;

    if (modalEl) {
      resizeObserver.observe(modalEl);
    }
    if (titleEl) {
      resizeObserver.observe(titleEl);
    }
    if (subtitleEl) {
      resizeObserver.observe(subtitleEl);
    }

    return () => {
      if (modalEl) {
        resizeObserver.unobserve(modalEl);
      }
      if (titleEl) {
        resizeObserver.unobserve(titleEl);
      }
      if (subtitleEl) {
        resizeObserver.unobserve(subtitleEl);
      }
    };
  }, [handleModalResize]);

  useEffect(() => {
    if (!pageSection) return;

    const pageSectionLocation = pageSection.getBoundingClientRect();

    setPageSectionWidth(pageSectionLocation.width);
  }, [windowSize.width]); // fire on window resize

  useEffect(() => {
    setActiveIndex(0);
  }, [images]);

  useEffect(() => {
    if (!pageSectionWidth) return;

    const newWidth = pageSectionWidth - 2 * sideMargin;

    if (windowSize.height && windowSize.width) {
      setHeight(
        Math.min(
          newWidth,
          windowSize.height -
            Math.min(windowSize.height * 0.05, modalTopPosition) * 2
        )
      );
    }

    if (pageSectionWidth) {
      setWidth(newWidth);
    }

    handleModalResize();
  }, [
    activeIndex,
    handleModalResize,
    pageSectionWidth,
    sideMargin,
    subtitleText,
    windowSize.height,
    windowSize.width
  ]);

  useLayoutEffect(() => {
    setSubtitleText(`Template Option Used: ${images[activeIndex]?.[0]}`);
  }, [activeIndex, images]);

  if (pageSectionWidth && windowSize.height && windowSize.width) {
    const pageSectionLeftPositionInBrowser =
      (windowSize.width - pageSectionWidth) / 2;
    const prevImageUrl = images[activeIndex - 1]?.[1];
    const activeImageUrl = images[activeIndex]?.[1];
    const nextImageUrl = images[activeIndex + 1]?.[1];
    const imageStripHeight = height - titleHeight - subtitleHeight;

    return (
      <Overlay onClick={onClose}>
        <ModalBackground
          ref={modalRef}
          style={{
            height,
            width,
            left: pageSectionLeftPositionInBrowser + sideMargin,
            top: windowSize.height / 2 - height / 2
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Title ref={titleRef}>Contributions for {projectTitle}</Title>
          <Images
            activeImage={activeImageUrl}
            height={imageStripHeight}
            modalHeight={height}
            nextImage={nextImageUrl}
            prevImage={prevImageUrl}
            subtitleHeight={subtitleHeight}
            titleHeight={titleHeight}
          />
          <Subtitle ref={subtitleRef}>{subtitleText}</Subtitle>
          <Controls
            activeIndex={activeIndex}
            buttonTopOffset={titleHeight}
            imageCount={images.length}
            imagesRef={null}
            imageStripHeight={imageStripHeight}
            modalWidth={width}
            onClose={onClose}
            onNext={handleClickNext}
            onPrev={handleClickPrev}
          />
        </ModalBackground>
      </Overlay>
    );
  } else {
    return null;
  }
}

function OtherContributions({ projectSlug, projectTitle }: PropsType) {
  const axios = useAxios();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [imageUrls, setImageUrls] = useState<TShareableImages>([]);
  const triggerButtonRef = useRef(
    document.querySelector('#view-other-contributions')
  );

  const handleButtonClick = useCallback(() => {
    setShowModal(true);

    if (!loaded) {
      setLoading(true);
    }
  }, [loaded]);

  useEffect(() => {
    const buttonEl = triggerButtonRef.current;

    if (!buttonEl) return;

    buttonEl.addEventListener('click', handleButtonClick);

    return () => buttonEl.removeEventListener('click', handleButtonClick);
  }, [handleButtonClick]);

  useEffect(() => {
    if (loaded) return;
    if (!loading) return;
    if (!showModal) return;

    axios.get(shareableImages(projectSlug)).then(
      (res) => {
        setLoaded(true);
        setLoading(false);
        setImageUrls(res.data);
      },
      () => {
        // failed, no op
      }
    );
  }, [axios, loaded, loading, showModal, projectSlug]);

  if (showModal) {
    return (
      <Modal
        images={imageUrls}
        imagesLoaded={loaded}
        projectTitle={projectTitle}
        onClose={() => setShowModal(false)}
      />
    );
  } else {
    return null;
  }
}

export default (props: PropsType) => <OtherContributions {...props} />;
