import { useLayoutEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default function useLockBodyScroll(targetElement?: HTMLElement | null) {
  const fallbackElementRef = useRef(document.createElement('div'));

  useLayoutEffect(() => {
    const element = targetElement ?? fallbackElementRef?.current;
    disableBodyScroll(element);

    return () => enableBodyScroll(element);
  }, [targetElement]);
}
